import styled from "@emotion/styled";
import React from "react";
import at from "../assets/images/at.svg";
import en from "../assets/images/en.svg";

import { Link } from "gatsby";
import { LOCALES } from "../config";

const Flag = styled.img`
  height: 34px;
  width: 34px;
  border-radius: 50%;
`;

const Container = styled(Link)`
  display: flex;
  justify-self: flex-end;
  flex-direction: row;
  padding-bottom: 40px;
  margin: 4px;
`;

const Language = ({ pages }: any) => {
  return (
    <>
      <Container to={pages?.find(page => page.locale === "en").path}>
        <Flag src={en} />
      </Container>
      <Container to={`at${pages?.find(page => page.locale === "de_AT").path}`}>
        <Flag src={at} />
      </Container>
    </>
  );
};

export default Language;
