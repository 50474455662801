import { Link } from "gatsby";
import React from "react";
import styled from "@emotion/styled";
import { PaymentProviders } from "../hooks/usePaymentProviders";

import SocialIcons from "./Social";
import Language from "./Language";
import eighteen from "../assets/images/eighteen.svg";
import mga from "../assets/images//icons/mga_logo.png";
import responsible from "../assets/images/responsible-gaming.svg";
import { LOCALES, PREFIX } from "../config";

interface FooterI {
  footerMenu: {
    body: {
      children: any[];
    };
  };
}

const FooterStyle = styled.footer`
  bottom: 0;
  padding: 40px 0;
  background-color: #441152;
  color: #fff;
  margin-top: 120px;
`;

const FooterWrapper = styled.div`
  max-width: 90%;
  margin: auto;
`;

const FooterContainer = styled.footer`
  margin: 20px auto 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 900px;
`;

const LinksColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;

const PageLinks = styled(Link)`
  text-decoration: none;
  margin: 0 20px 20px 0;
  color: #fff;
  :hover {
    text-decoration: underline #fff;
  }
`;

const PolicyLinksColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  border-bottom: 1px solid white;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const PolicyLinks = styled.a`
  text-decoration: none;
  margin: 0 10px 10px 0;
  color: #fff;
  :hover {
    text-decoration: underline #fff;
  }
`;

const SocialColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;

const PaymentRow = styled.div`
  display: flex;
  justify-content: center;
`;
const PaymentProvider = styled.img`
  margin: 0 10px 20px 0;
  height: 20px;
`;

const CertRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid white;
`;
const Cert = styled.img`
  margin: 0 10px 20px 0;
  height: 30px;
`;

const CertTitle = styled.h4`
  text-align: center;
`;

const Footer = ({ footerMenu, location, pages }: FooterI) => {
  const pageLinks = footerMenu?.body?.children?.filter(
    child => child.__typename === "PageLinkBlock"
  );

  const externalLinks = footerMenu?.body.children.filter(
    child => child.__typename === "ExternalLinkBlock"
  );

  const paymentProvider = PaymentProviders();
  const paymentImages = paymentProvider.map((node: any) => node.body);
  const parts = location?.pathname?.split("/");
  const locale = parts?.length > 1 && PREFIX.includes(parts[1]) ? parts[1] : "";
  return (
    <FooterStyle>
      <FooterWrapper>
        <FooterContainer>
          <LinksColumn>
            <Language location={location} pages={pages} />
          </LinksColumn>
          <LinksColumn>
            {pageLinks?.map(pageLink => {
              return (
                <PageLinks
                  key={pageLink.page.title}
                  to={`${locale}${pageLink?.page?.path}`}
                  activeClassName="active"
                >
                  {pageLink?.page?.menuTitle}
                </PageLinks>
              );
            })}
          </LinksColumn>
        </FooterContainer>
        <FooterContainer>
          <PolicyLinksColumn>
            {externalLinks?.map(link => {
              return (
                <PolicyLinks
                  key={link.externalLink.title}
                  href={link.externalLink.url}
                  rel={link.externalLink.rel}
                >
                  {link.externalLink.title}
                </PolicyLinks>
              );
            })}
          </PolicyLinksColumn>
        </FooterContainer>
        <FooterContainer>
          <CertTitle>Safety and Certificates</CertTitle>
          <CertRow>
            <Cert src={eighteen} alt="" />
            <Cert src={mga} alt="mga" />
            <Cert src={responsible} alt="Responsible Gaming" />
          </CertRow>
        </FooterContainer>
        <FooterContainer>
          <PaymentRow>
            {paymentImages.map((child: any) => {
              return child.childrenImageBlock.map((c: any) => {
                return (
                  <PaymentProvider
                    key={c.image.alt}
                    src={c.image.image.fluid.src}
                    alt={c.image.alt}
                  />
                );
              });
            })}
          </PaymentRow>
        </FooterContainer>
        <SocialColumn>
          <SocialIcons />
        </SocialColumn>
      </FooterWrapper>
    </FooterStyle>
  );
};

export default Footer;
